import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import { getSearchSuggestionAsync } from "../../../../redux/slices/AuthSlice";
import "./CanResumeSkillForm.css";

const CanResumeSkillForm = ({
  keySkills,
  onSkillAddHandler,
  onSkillSaveHandler,
  onSkillDeleteHandler,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [allSkillTags, setAllSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      name: Yup.string(),
    }),

    onSubmit: async (data, { resetForm, setErrors }) => {
      setLoader(true);

      const timer = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timer);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const onSkillAdd = async (curentSkill) => {
    const enteredSkill = curentSkill?.name?.trim()?.toLowerCase();
    const existingSkills = keySkills?.map((skill) =>
      skill?.name?.toLowerCase()
    );

    if (enteredSkill === "") {
      formik?.setFieldError("name", "Please Enter Skill");
      return;
    } else if (existingSkills?.includes(enteredSkill)) {
      formik?.setFieldError("name", "The Skill is already added");
      return;
    } else {
      onSkillAddHandler(curentSkill);
      await formik.setFieldValue("name", "");
    }
  };

  const onSkillCancel = async (currentIndex) => {
    onSkillDeleteHandler(currentIndex);
  };

  const onSkillTagsChange = async (e) => {
    const { name, value } = e?.target;
    await formik.setFieldValue(name, value);
    getSearchSuggestion(encodeURIComponent(value), "jobTag");
  };

  const onSkillTagsClick = async (curentSkillTags) => {
    const newSkill = {name: curentSkillTags};
    onSkillAdd(newSkill);
    setAllSkillTagsHide(true);
    await formik.setFieldValue("name", "");
  };

  const getSkillData = async () => {
    await formik.setFieldValue("name", "");
  };

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      dispatch(
        getSearchSuggestionAsync({
          searchValue: searchValue,
          searchType: searchType,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (searchType === "jobTag") {
            if (res?.job_tags?.length > 0) {
              setAllSkillTags(res?.job_tags);
              setAllSkillTagsHide(false);
            } else {
              setAllSkillTags([]);
              setAllSkillTagsHide(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formik?.resetForm();
    getSkillData();
  }, []);

  return (
    <div className="accordion-item experince-res-ver-right-skills">
      <button
        className="accordion-button resume-skills-right-tb"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sub-collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            className={`address-res f-feild-res tags-tittles ${
              isFormFieldValid("name") && "error-input"
            }`}
          >
            <label for="lname">Skills</label>
            <br />

            <OutsideClickHandler
              onOutsideClick={() => {
                setAllSkillTagsHide(true);
              }}
            >
              <input
                type="text"
                placeholder="Enter Skills"
                className="feild-stl-res form-font-f"
                name="name"
                value={formik?.values?.name}
                onChange={(e) => {
                  onSkillTagsChange(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
              />
            </OutsideClickHandler>

            {getFormErrorMessage("name")}

            {allSkillTags?.length > 0 && (
              <div
                className={`job-tags-titles-dropdown ${allSkillTagsHide && "job-dropdown-hide"
                  }`}
              >
                <ul className="dorpdown-list-location">
                  {allSkillTags.map((j, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        onSkillTagsClick(j?.name);
                      }}
                    >
                      {" "}
                      {j?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {keySkills?.length > 0 && (
              <div className="tags-skills">
                <ul className="tags-titles-list gap-2">
                  {keySkills.map((j, i) => (
                    <li key={i}>
                      {j?.name}
                      <span onClick={() => onSkillCancel(i)}>
                        <img src="../image/cross.png" alt="" />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </form>
      </button>
    </div>
  );
};

export default CanResumeSkillForm;
